<template>
<!-- v-if="currentJobType!=3 && !isPosterHR" -->
    <div class="avatar-container" >
        <el-popover
            ref="userProfilePopover"
            popper-class="user-profile"
            :placement="placement"
            :width="showCard ? 320 : 150"
            v-if="enableCard"
            trigger="manual"
            v-model="isShowCard"
            @show="getCard"
        >
            <div class="user-profile-container" v-show="showCard">
                <div class="user-profile-head">
                    <div class="user-avatar">
                        <a class="user-avatar-box" :href="userLink" target="_blank">
                            <img :src="`${user.avatar}?size=100`" width="80" height="80" v-if="user.avatar" />
                            <img src="~@src/assets/images/default-user.png" width="80" height="80" v-else/>
                        </a>
                    </div>
                    <div class="user-info">
                        <!-- :style="userId != userInfo.id ? 'margin-right: 65px;' : ''" -->
                        <p class="user-info-name" >
                            <span class="user-online" :class="isOnline ? 'is-online' : ''"></span>
                            <a class="user-info-realname" :href="userLink" target="_blank">{{user.realName}}</a>
                            <span class="user-info-nickname" v-if="user.nickName">{{user.nickName}}</span>
                        </p>
                        <p class="user-info-job">
                            {{user.firmName}}<span v-if="user.firmName && user.title"> | </span>{{user.title}}
                        </p>
                        <p class="user-info-slogan" :title="user.slogan">{{user.slogan}}</p>
                    </div>
                    <!-- <div class="user-follow" v-if="userId != userInfo.id">
                        <button ref="followBtn" id="followBtn" class="user-follow-btn" :class="user.isFollowedByMe ? 'user-has-follow' : ''" @click="followUser"><i class="icon-font" :class="user.isFollowedByMe ? 'icon-agree' : 'icon-tianjia'"></i>{{followText}}</button>
                    </div> -->
                    <div class="user-preference" v-show="user.adeptTags?.length > 0">
                        <span class="user-preference-title">职位/人才偏好：</span><br/>
                        <p>{{user.adeptTags?.join(' / ')}}</p>
                    </div>
                </div>
                <div class="user-profile-foot">
                    <ul class="user-count-list">
                        <template v-if="user.identityType == 2">
                            <li class="user-count-item">
                                <span class="user-count-item-count">{{user.ordered}}</span>
                                <span class="user-count-item-label">抢单</span>
                            </li>
                            <li class="user-count-item">
                                <span class="user-count-item-count">{{user.recommended}}</span>
                                <span class="user-count-item-label">推荐</span>
                            </li>
                            <li class="user-count-item">
                                <span class="user-count-item-count">{{user.interviewed}}</span>
                                <span class="user-count-item-label">面试</span>
                            </li>
                        </template>

                        <template v-if="user.identityType == 1 || user.identityType == 3">
                            <li class="user-count-item">
                                <span class="user-count-item-count">{{user.jobs}}</span>
                                <span class="user-count-item-label">职位</span>
                            </li>
                            <li class="user-count-item">
                                <span class="user-count-item-count">{{user.candidates}}</span>
                                <span class="user-count-item-label">候选人</span>
                            </li>
                            <li class="user-count-item">
                                <span class="user-count-item-count">{{user.accepted}}</span>
                                <span class="user-count-item-label">接受</span>
                            </li>
                            <li class="user-count-item">
                                <span class="user-count-item-count">{{user.rejected}}</span>
                                <span class="user-count-item-label">拒绝</span>
                            </li>
                        </template>

                        <li class="user-count-item">
                            <span class="user-count-item-count">{{user.offered}}</span>
                            <span class="user-count-item-label">Offer</span>
                        </li>
                    </ul>
                    <div class="user-message" v-show="userId != userInfo.id">
                        <span class="btn-primary__send" @click="handleSendMessage">发消息</span>
                    </div>
                </div>
                <div class="user-dimission-status" v-if="isShowUserDimissionStatus"></div>
            </div>
            <!-- 客户页面划过综合协调人显示名片 -->
            <div
                v-if="showGenCoordinator"
                class="user-base-info"
                slot="reference"
                @click="handleReferenceElmClick"
            >
                <span class="general-coordinator-item" v-if="enableName">{{realName}}<span v-if="nickName" class="text-grey">@{{nickName}} </span></span>
            </div>
            <div
                v-else
                class="user-base-info"
                slot="reference"
                @click="handleReferenceElmClick"
            >
                <span
                    v-if="enableRefImage"
                    class="avatar"
                    :class="'avatar-' + shape + ' avatar-' + size"
                >
                    <img :src="`${src}?size=${imgSize}`" :alt="alt" class="avatar-img" v-if="src"/>
                    <img src="~@src/assets/images/default-user.png" :width="imgSize" :height="imgSize" :alt="alt" class="avatar-img" v-else/>
                </span>
                <span class="user-name" v-if="enableName">{{realName}}<span v-if="nickName" class="text-grey">@{{nickName}}</span></span>
            </div>
        </el-popover>
        <template v-else>
            <a
                v-if="enableLink"
                class="avatar"
                :href="userLink"
                target="_blank"
                :class="enableLink ? 'avatar-link avatar-' + shape + ' avatar-' + size : 'avatar-' + shape + ' avatar-' + size"
            >
                <img :src="`${src}?size=${imgSize}`" :alt="alt" class="avatar-img" v-if="src"/>
                <img src="~@src/assets/images/default-user.png" :width="imgSize" :height="imgSize" :alt="alt" class="avatar-img" v-else/>
            </a>
            <span
                v-else
                class="avatar"
                :class="'avatar-' + shape + ' avatar-' + size"
            >
                <img :src="`${src}?size=${imgSize}`" :alt="alt" class="avatar-img" v-if="src"/>
                <img src="~@src/assets/images/default-user.png" :width="imgSize" :height="imgSize" :alt="alt" class="avatar-img" v-else/>
            </span>
        </template>
    </div>
    <!-- 是HR时，显示其二维码 -->
    <!-- <div v-else>
        <el-popover
            placement="top-start"
            width="250"
            trigger="hover"
            @show="contactPublisher(userId)">
            <div class="hr-contact-card" v-loading="contactLoading">
                <div class="hr-info">
                    <img :src="hrInfo.avatarUrl" alt="">
                    <div class="hr-base-info">
                        <p class="hr-name" :title="hrInfo.fullName">{{ hrInfo.fullName }}</p>
                        <p class="hr-phone">{{ hrInfo.mobile }}</p>
                    </div>
                </div>
                <div class="hr-qr-code">
                    <img :src="hrInfo.wechatQRCodeUrl" alt="">
                </div>
            </div>
            <span
                slot="reference" 
                class="avatar"
                :class="'avatar-' + shape + ' avatar-' + size"
            >
                <img :src="`${src}?size=${imgSize}`" :alt="alt" class="avatar-img" v-if="src" />
                <img src="~@src/assets/images/default-user.png" :width="imgSize" :height="imgSize" :alt="alt" class="avatar-img" v-else/>
            </span>
        </el-popover>
    </div> -->
</template>

<script>
import { user as userUrl } from '#/js/config/api.json';
import emitter from '@src/js/mixins/emitter';
// import imService from '#/js/service/imService.js';
import imService from '#/js/service/openIMService.js';

const POPOVER_MIN_HEIGHT = 167;
const POPOVER_TABS_HEIGHT = 72;
const POPOVER_MASSAGE_HEIGHT = 40;
//后端映射关系：Mini: 30, Small: 60, Medium: 100, Large: 180, Raw:2048(原图规格)
//取最近的后端有的规格，且向上取
const sizeMap = {
    "sm": 30,
    // "md": 40,
    "md": 60,
    "mg": 60,
    // "lg": 80
    "lg": 100
}

export default {
    name: 'avatar',
    mixins: [emitter],
    data() {
        return {
            contactLoading: false,
            hrInfo: {},
            showCard: false,
            user: {
                identityType: 0,
                avatar: "",
                realName: '',
                nickName: '',
                firmName: '',
                title: '',
                slogan: '',
                ordered: 0,
                recommended: 0,
                interviewed: 0,
                jobs: 0,
                candidates: 0,
                accepted: 0,
                rejected: 0,
                offered: 0,
                isFollowedByMe: false,
                adeptTags: []
            },
            isOnline: false,
            message: '',
            offset: 0,
            loading: '',
            isShowCard: false,
            showTimer: null
        }
    },
    props: {
        /**
         * @param currentJobType: 职位类别
         */
        currentJobType: Number,
        /**
         * @param isPosterHR: 是否是HR
         */
        isPosterHR: Boolean,
        /**
         * @param enableRefImage: 是否展示popover占位头像
         */
        enableRefImage: {
            type: Boolean,
            default: true
        },
        showGenCoordinator: {
            type: Boolean,
            required: false,
            default: false
        },
        /**
         * @param src: 头像url
         */
        src: String,
        /**
         * @param alt: 头像提示语
         */
        alt: String,
        /**
         * @param userId: 用户个人ID
         */
        userId: String,
        /**
         * @param enableCard: 是否展示个人信息名片
         */
        enableCard: {
            type: Boolean,
            default: true
        },
        /**
         * @param enableLink: 是否可以跳转到个人信息页
         */
        enableLink: {
            type: Boolean,
            default: true
        },
        /**
         * @param shape: 头像形状
         * 可选值：circle：圆形
         *        round：4px圆角
         *        square：方形
         */
        shape: {
            type: String,
            default: 'circle'
        },
        /**
         * @param size:头像大小
         * 可选值：sm：26px*26px
         *        md：40px*40px
         *        mg：60px*60px
         *        lg：80px*80px
         */
        size: {
            type: String,
            default: 'sm'
        },
        /**
         * @param isTalentPool: 是否是talentPool或trackingList表格
         */
        isTalentPool: {
            type: Boolean,
            default: false
        },
        enableName: {
            type: Boolean,
            default: false
        },
        realName: {
            type: String,
            default: ""
        },
        nickName: {
            type: String,
            default: ""
        },
        isRecommendation: {
            type: Boolean,
            default: false
        },
        placement: {
            type: String,
            default: 'top-end'
        },
        shareData: {
            type: Object
        }
    },
    computed: {
        userLink() {
            // return this.userId ? (this.userId === this.userInfo.id ? '/Headhunting/MyCompany.html#/personalCenter' : `/Headhunting/MyCompany.html#/user/${this.userId}`) : 'javascript: void(0);'
            // return this.userId ? (this.userId === this.userInfo.id ? '/#/personalCenter' : `/Headhunting/MyCompany.html#/user/${this.userId}`) : 'javascript: void(0);'
            return this.userId ? (this.userId === this.userInfo.id ? '/#/personalCenter' : `/#/user/${this.userId}`) : 'javascript: void(0);'
        },
        userInfo() {
            return this.$store.state.user.userInfo;
        },
        followText() {
            return this.user.isFollowedByMe ? '' : '关注';
        },
        imgSize() {
            return sizeMap[this.size];
        },
        isShowUserDimissionStatus() {
            if([3, 5].includes(this.currentJobType)) return false;
            if(location.href.indexOf('/candidates/firmCandidate') > -1 || location.href.indexOf('/candidateDetail') > -1) {
                if(this.user.firmId === this.userInfo.firmId || (this.userInfo.candidateAuthorizationSourceFirmIds || []).indexOf(this.user.firmId) > -1) {
                    //头像用户的公司ID和自己的公司ID相同 或者 头像用户的用户的公司ID在授权自己公司的公司列表里面
                    return false;
                } else {
                    return true;
                }
            }
            return false;
        }
    },
    mounted() {
        let referenceElm = this.$refs.userProfilePopover ? this.$refs.userProfilePopover.referenceElm : null;
        if(referenceElm) {
            referenceElm.addEventListener('mouseenter', this.handleMouseEnter);
            referenceElm.addEventListener('mouseleave', this.handleMouseLeave);
        }
    },

    methods: {
        contactPublisher(userId) {
            console.log(userId)
            this.dialogVisible = true;
            this.contactLoading = true;
            _request({
                url: '/openapi/User/%p/GetWechatQRCodeInfo'.replace(/%p/g, userId),
                method: "GET",
                baseURL: "LbdOpenApi",
            }).then((res) => {
                console.log(res);
                this.hrInfo = res;
            }).catch((err) => {
                console.log(err)
            }).finally(() => {
                this.contactLoading = false;
            })
        },

        getCard() {
            if(location.hash.indexOf('/floatingList') > -1) {
                let avatarType = location.hash.indexOf('/floatingList/myRecommend') > -1 ? 'projectManager' : 'recommender';
                if(window.lbdTrack){
                    lbdTrack.track('FloatListAvatarCardShow',{
                        type: avatarType
                    })
                } else {
                    _tracker.track('FloatListAvatarCardShow',JSON.stringify({
                        type: avatarType
                    }))
                }
            }
            let popperElm = this.$refs.userProfilePopover.popperElm;
            popperElm.addEventListener('mouseenter', this.handleMouseEnter);
            popperElm.addEventListener('mouseleave', this.handleMouseLeave);
            this.showCard = false;
            this.loading = this.$loading({
                target: popperElm
            })
            // console.log(`当前用户的userId为：${this.userId}`);
            _request({
                method: "GET",
                url: userUrl.user_card.replace(/\%p/ig, this.userId),
            }).then(res =>{
                this.user = res;
                console.log(this.user)
                this.getUserOnlineStatus(() => {
                    this.$nextTick(() => {
                        this.loading.close();
                    });
                    if(this.placement.indexOf('top') > -1) {
                        this.offset = POPOVER_MIN_HEIGHT;
                        if(this.userId != this.userInfo.id) {
                            this.offset += POPOVER_MASSAGE_HEIGHT;
                        }
                        if(res.adeptTags?.length > 0) {
                            this.offset += POPOVER_TABS_HEIGHT;
                        }
                        popperElm.style.top = (parseInt(popperElm.style.top) - this.offset + 60) + 'px';
                        if(this.isTalentPool) {
                            popperElm.style.left = (parseInt(popperElm.style.left) - 150) + 'px';
                            popperElm.children[2].style.left = (parseInt(popperElm.children[2].style.left) + 150) +'px';
                        }
                    }
                    this.showCard = true;
                });
            }).catch(err =>{
                this.$nextTick(() => {
                    this.loading.close();
                });
                console.log(err);
            })
        },
        followUser() {
            let followBtn = document.getElementById('followBtn');
            this.loading = this.$loading({
                target: followBtn
            })
            if(this.user.isFollowedByMe) {
                //unFollow
                _request({
                    method: 'POST',
                    url: userUrl.unfollow_user.replace(/\%p/ig, this.userId)
                }).then(res => {
                    this.user.isFollowedByMe = false;
                    this.$nextTick(() => {
                        this.loading.close();
                    });
                }).catch(err =>{
                    this.$nextTick(() => {
                        this.loading.close();
                    });
                    console.log(err);
                })
            } else {
                //follow
                _request({
                    method: 'POST',
                    url: userUrl.follow_user.replace(/\%p/ig, this.userId)
                }).then(res => {
                    this.user.isFollowedByMe = true;
                    this.$nextTick(() => {
                        this.loading.close();
                    });
                }).catch(err =>{
                    this.$nextTick(() => {
                        this.loading.close();
                    });
                    console.log(err);
                })
            }
        },
        handleSendMessage() {
            if(window.lbdTrack){
                lbdTrack.track('UserCardSendMessageClick', {})
            } else {
                _tracker.track('UserCardSendMessageClick')
            }
            let isShare = false,
                json = {};
            let candidateUrlList = ['/candidateDetail', '/floatingList', '/trackingList', '/talentPool'],
                jobUrlList = ['/Job/', '/jobList', '/customer/'];
            let isCandidateShareUrl = candidateUrlList.some(item => {
                    return location.hash.indexOf(item) > -1;
                }),
                isJobShareUrl = jobUrlList.some(item => {
                    return location.hash.indexOf(item) > -1;
                });
            if(isCandidateShareUrl && this.shareData.candidateName) {
                //候选人分享
                isShare = true;
                json = Object.assign({
                    type: 'candidateShare',
                    unionId: this.user.unionId
                }, this.shareData);
            } else if(isJobShareUrl && this.shareData.jobName) {
                //职位分享
                isShare = true;
                json = Object.assign({
                    type: 'jobShare',
                    unionId: this.user.unionId
                }, this.shareData);
            } else {
                //普通指定成员单聊
                json = {
                    unionId: this.user.unionId
                };
            }
            this.dispatch('App', 'showInstantMessage', {
                isShare: isShare,
                json: json
            });
            // localStorage.setItem('sendMessageUserId', this.userId);
            // window.open(`/My/Messages`);
        },
        sendMessage() {
            let message = this.message || '';
            if(!message) {
                return shortTips('消息不能为空');
            }else if(message.length > 400) {
                return shortTips(`消息不能超过400个字`);
            } else if(/(\<[a-zA-Z\!\/\?])|(&#)/.test(message)) {
                shortTips('您输入的内容包含非法字符，请重新输入');
                return;
            } else {
                let param = {
                    atUserIds: this.userId,
                    comment: message
                }
                _request({
                    method: 'POST',
                    url: userUrl.direct_message,
                    data: param
                }).then(res => {
                    this.message = '';
                    shortTips('发送成功');
                }).catch(err => {
                    console.log(err)
                })
            }
        },
        handleReferenceElmClick() {
            this.isShowCard = true;
        },
        handleMouseEnter() {
            clearTimeout(this.showTimer);
            this.showTimer = setTimeout(() => {
                this.isShowCard = true;
            }, 1000);
        },
        handleMouseLeave() {
            clearTimeout(this.showTimer);
            this.showTimer = setTimeout(() => {
                this.isShowCard = false;
            }, 200);
        },
        getUserOnlineStatus(cb) {
            imService.getUserOnlineStatus({
                accountIds: [this.user.unionId]
            }).then(result => {
                if(result && result.length) {
                    if(result[0].state === 'Online') {
                        this.isOnline = true;
                    } else {
                        this.isOnline = false;
                    }
                }
            }).catch(err => {
                console.log('getUserCardOnlineStatusError', err);
            }).finally(() => {
                cb && cb();
            });
        }
    }
}
</script>

<style lang="scss" scoped>
.hr-contact-card {
    padding: 10px;
    .hr-info {
        margin-bottom: 10px;
        display: flex;
        img {
            margin-right: 20px;
            width: 60px;
            height: 60px;
            border-radius: 50%;
        }
        .hr-base-info {
            .hr-name {
                // font-size: 16px;
                // color: #333333;
                font-size: 14px;
                color: #B9B9B9;
                width: 130px;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
            }
            .hr-phone {
                font-size: 16px;
                color: #333333;
            }
        }
    }
    .hr-qr-code {
        width: 180px;
        height: 180px;
        // background-color: #36B495;
        margin: auto;
        img {
            width: 100%;
            height: 100%;
        }
    }
}

.user-base-info {
    display: inline-flex;
    align-items: center;
    .user-name {
        margin-left: 5px;
        color: #333;
        .text-grey {
            color: #b9b9b9;
        }
    }
    .general-coordinator-item{
        cursor: pointer;
        color:#38BC9D
    }
}
.avatar {
    border: solid 1px #ececec;
    display: inline-block;
    position: relative;
    overflow: hidden;
    .avatar-img {
        width: 100%;
        height: auto;
        display: inline-block;
        vertical-align: top;
    }
}

.avatar-link {
    cursor: pointer;
}

//size

.avatar-lg {
    width: 80px;
    height: 80px;
}

.avatar-mg {
    width: 60px;
    height: 60px;
}

.avatar-ld {
    width: 50px;
    height: 50px;
}

.avatar-md {
    width: 40px;
    height: 40px;
}

.avatar-sd {
    width: 30px;
    height: 30px;
}

.avatar-sm {
    width: 26px;
    height: 26px;
}

// shape

.avatar-circle {
    border-radius: 50%;
}

.avatar-round {
    border-radius: 4px;
}

.avatar-square {
    border-radius: 0;
}

.user-profile {
    &-container {
        position: relative;
        .user-dimission-status {
            position: absolute;
            left: 50%;
            top: 40%;
            width: 138px;
            height: 75px;
            transform: translate(-50%, -50%);
            background: url("~@src/assets/images/dimission_image.png") no-repeat center;
        }
    }
    &-head {
        min-height: 110px;
        padding: 15px;
        padding-bottom: 10px;
        .user-avatar {
            margin-right: 10px;
            float: left;
            &-box {
                width: 80px;
                height: 80px;
                display: block;
                border-radius: 50%;
                overflow: hidden;
                & > img {
                    width: 100%;
                    height: 100%;
                }
            }
        }
        .user-info {
            height: 78px;
            & > p {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                margin-bottom: 0;
            }

            &-name {
                line-height: 27px;
                color: #b9b9b9;
                margin-top: 2.5px;
                margin-bottom: 5px;
                &:hover {
                    cursor: pointer;                    
                }
                cursor: pointer;
                .user-online {
                    float: left;
                    width: 8px;
                    height: 8px;
                    margin-top: 10px;
                    background-color: #b9b9b9;
                    border-radius: 50%;
                    margin-right: 5px;
                    cursor: default;
                    &.is-online {
                        background-color: $primary;
                    }
                }
                .user-info-realname {
                    color: #333;
                    font-size: 16px;
                    margin-right: 2.5px;
                    &:hover {
                        cursor: pointer;
                        color: $primary;                
                    }
                }
                .user-info-nickname {
                    font-size: 14px;
                }
            }
            &-job {
                font-size: 14px;
                color: #666;
            }
            &-slogan {
                max-height: 32px;
                color: #b9b9b9;
                font-size: 12px;
                white-space: normal !important;
            }
        }
        .user-follow {
            top: 16px;
            right: 15px;
            position: absolute;
            &-btn {
                width: 65px;
                height: 30px;
                line-height: 30px;
                font-size: 12px;
                border-radius: 2px;
                background-color: #38bc9c;
                border: 1px solid #38bc9c;
                color: #fff;
                text-align: center;
                position: relative;
                &:focus {
                    outline: none;
                }
                .icon-font {
                    margin-top: -4px;
                    font-size: 12px;
                    color: #fff;
                }
                .icon-tianjia {
                    margin-right: 5px;
                }
                &.user-has-follow{
                    &:hover {
                        background: #e4eaec;
                        border: none;
                        .icon-agree::before {
                            content: "\e607";
                        }
                        &::after {
                            padding-left: 5px;
                            content: "取消";
                        }
                    }
                }
            }
        }
        .user-preference {
            padding: 10px 9px 0px;
            font-size: 14px;
            line-height: 20px;
            color: #666;
            height: 75px;
            & > p{
                max-height: 40px;
                margin-bottom: 0;
                overflow: hidden;
            }
            &-title {
                font-weight: bold;
            }
        }
    }
    &-foot {
        width: 100%;
        background-color: #f5f5f5;
        padding-top: 10px;
        padding-bottom: 8px;
        .user-count-list {
            display: flex;
            flex-wrap: nowrap;
            .user-count-item {
                flex-grow: 1;
                text-align: center;
                display: inline-flex;
                flex-direction: column;
                line-height: 1;
                position: relative;
                &::after {
                    content: "";
                    width: 1px;
                    height: 100%;
                    background-color: #e1e1e1;
                    position: absolute;
                    top: 0;
                    right: 0;
                }
                &:nth-last-of-type(1):after {
                    display: none;
                }
                &-count {
                    color: #333;
                    font-size: 20px;
                    padding-left: 2.5px;
                    padding-right: 2.5px;
                    margin-bottom: 5px;
                }
                &-label {
                    color: #b9b9b9;
                    font-size: 12px;
                }
            }
        }
        .user-message {
            text-align: center;
            padding: 6px 24px;
            .btn-primary__send{
                display: block;
                width: 100%;
                -webkit-appearance: none;
                outline: none!important;
                border: none;
                text-align: center;
                font-size: 12px;
                color: #fff;
                height: 26px;
                line-height: 26px;
                background-color: $primary;
                border-radius: 4px;
                padding: 0;
                z-index: 60;
                cursor: pointer;
            }
        }
    }
}
</style>

<style lang="scss">

.user-profile.el-popover {
    padding: 0;
    min-height: 60px;
}

</style>
