var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "avatar-container" },
    [
      _vm.enableCard
        ? _c(
            "el-popover",
            {
              ref: "userProfilePopover",
              attrs: {
                "popper-class": "user-profile",
                placement: _vm.placement,
                width: _vm.showCard ? 320 : 150,
                trigger: "manual",
              },
              on: { show: _vm.getCard },
              model: {
                value: _vm.isShowCard,
                callback: function ($$v) {
                  _vm.isShowCard = $$v
                },
                expression: "isShowCard",
              },
            },
            [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.showCard,
                      expression: "showCard",
                    },
                  ],
                  staticClass: "user-profile-container",
                },
                [
                  _c("div", { staticClass: "user-profile-head" }, [
                    _c("div", { staticClass: "user-avatar" }, [
                      _c(
                        "a",
                        {
                          staticClass: "user-avatar-box",
                          attrs: { href: _vm.userLink, target: "_blank" },
                        },
                        [
                          _vm.user.avatar
                            ? _c("img", {
                                attrs: {
                                  src: `${_vm.user.avatar}?size=100`,
                                  width: "80",
                                  height: "80",
                                },
                              })
                            : _c("img", {
                                attrs: {
                                  src: require("@src/assets/images/default-user.png"),
                                  width: "80",
                                  height: "80",
                                },
                              }),
                        ]
                      ),
                    ]),
                    _c("div", { staticClass: "user-info" }, [
                      _c("p", { staticClass: "user-info-name" }, [
                        _c("span", {
                          staticClass: "user-online",
                          class: _vm.isOnline ? "is-online" : "",
                        }),
                        _c(
                          "a",
                          {
                            staticClass: "user-info-realname",
                            attrs: { href: _vm.userLink, target: "_blank" },
                          },
                          [_vm._v(_vm._s(_vm.user.realName))]
                        ),
                        _vm.user.nickName
                          ? _c("span", { staticClass: "user-info-nickname" }, [
                              _vm._v(_vm._s(_vm.user.nickName)),
                            ])
                          : _vm._e(),
                      ]),
                      _c("p", { staticClass: "user-info-job" }, [
                        _vm._v(
                          "\r\n                            " +
                            _vm._s(_vm.user.firmName)
                        ),
                        _vm.user.firmName && _vm.user.title
                          ? _c("span", [_vm._v(" | ")])
                          : _vm._e(),
                        _vm._v(
                          _vm._s(_vm.user.title) +
                            "\r\n                        "
                        ),
                      ]),
                      _c(
                        "p",
                        {
                          staticClass: "user-info-slogan",
                          attrs: { title: _vm.user.slogan },
                        },
                        [_vm._v(_vm._s(_vm.user.slogan))]
                      ),
                    ]),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.user.adeptTags?.length > 0,
                            expression: "user.adeptTags?.length > 0",
                          },
                        ],
                        staticClass: "user-preference",
                      },
                      [
                        _c("span", { staticClass: "user-preference-title" }, [
                          _vm._v("职位/人才偏好："),
                        ]),
                        _c("br"),
                        _c("p", [
                          _vm._v(_vm._s(_vm.user.adeptTags?.join(" / "))),
                        ]),
                      ]
                    ),
                  ]),
                  _c("div", { staticClass: "user-profile-foot" }, [
                    _c(
                      "ul",
                      { staticClass: "user-count-list" },
                      [
                        _vm.user.identityType == 2
                          ? [
                              _c("li", { staticClass: "user-count-item" }, [
                                _c(
                                  "span",
                                  { staticClass: "user-count-item-count" },
                                  [_vm._v(_vm._s(_vm.user.ordered))]
                                ),
                                _c(
                                  "span",
                                  { staticClass: "user-count-item-label" },
                                  [_vm._v("抢单")]
                                ),
                              ]),
                              _c("li", { staticClass: "user-count-item" }, [
                                _c(
                                  "span",
                                  { staticClass: "user-count-item-count" },
                                  [_vm._v(_vm._s(_vm.user.recommended))]
                                ),
                                _c(
                                  "span",
                                  { staticClass: "user-count-item-label" },
                                  [_vm._v("推荐")]
                                ),
                              ]),
                              _c("li", { staticClass: "user-count-item" }, [
                                _c(
                                  "span",
                                  { staticClass: "user-count-item-count" },
                                  [_vm._v(_vm._s(_vm.user.interviewed))]
                                ),
                                _c(
                                  "span",
                                  { staticClass: "user-count-item-label" },
                                  [_vm._v("面试")]
                                ),
                              ]),
                            ]
                          : _vm._e(),
                        _vm.user.identityType == 1 || _vm.user.identityType == 3
                          ? [
                              _c("li", { staticClass: "user-count-item" }, [
                                _c(
                                  "span",
                                  { staticClass: "user-count-item-count" },
                                  [_vm._v(_vm._s(_vm.user.jobs))]
                                ),
                                _c(
                                  "span",
                                  { staticClass: "user-count-item-label" },
                                  [_vm._v("职位")]
                                ),
                              ]),
                              _c("li", { staticClass: "user-count-item" }, [
                                _c(
                                  "span",
                                  { staticClass: "user-count-item-count" },
                                  [_vm._v(_vm._s(_vm.user.candidates))]
                                ),
                                _c(
                                  "span",
                                  { staticClass: "user-count-item-label" },
                                  [_vm._v("候选人")]
                                ),
                              ]),
                              _c("li", { staticClass: "user-count-item" }, [
                                _c(
                                  "span",
                                  { staticClass: "user-count-item-count" },
                                  [_vm._v(_vm._s(_vm.user.accepted))]
                                ),
                                _c(
                                  "span",
                                  { staticClass: "user-count-item-label" },
                                  [_vm._v("接受")]
                                ),
                              ]),
                              _c("li", { staticClass: "user-count-item" }, [
                                _c(
                                  "span",
                                  { staticClass: "user-count-item-count" },
                                  [_vm._v(_vm._s(_vm.user.rejected))]
                                ),
                                _c(
                                  "span",
                                  { staticClass: "user-count-item-label" },
                                  [_vm._v("拒绝")]
                                ),
                              ]),
                            ]
                          : _vm._e(),
                        _c("li", { staticClass: "user-count-item" }, [
                          _c("span", { staticClass: "user-count-item-count" }, [
                            _vm._v(_vm._s(_vm.user.offered)),
                          ]),
                          _c("span", { staticClass: "user-count-item-label" }, [
                            _vm._v("Offer"),
                          ]),
                        ]),
                      ],
                      2
                    ),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.userId != _vm.userInfo.id,
                            expression: "userId != userInfo.id",
                          },
                        ],
                        staticClass: "user-message",
                      },
                      [
                        _c(
                          "span",
                          {
                            staticClass: "btn-primary__send",
                            on: { click: _vm.handleSendMessage },
                          },
                          [_vm._v("发消息")]
                        ),
                      ]
                    ),
                  ]),
                  _vm.isShowUserDimissionStatus
                    ? _c("div", { staticClass: "user-dimission-status" })
                    : _vm._e(),
                ]
              ),
              _vm.showGenCoordinator
                ? _c(
                    "div",
                    {
                      staticClass: "user-base-info",
                      attrs: { slot: "reference" },
                      on: { click: _vm.handleReferenceElmClick },
                      slot: "reference",
                    },
                    [
                      _vm.enableName
                        ? _c(
                            "span",
                            { staticClass: "general-coordinator-item" },
                            [
                              _vm._v(_vm._s(_vm.realName)),
                              _vm.nickName
                                ? _c("span", { staticClass: "text-grey" }, [
                                    _vm._v("@" + _vm._s(_vm.nickName) + " "),
                                  ])
                                : _vm._e(),
                            ]
                          )
                        : _vm._e(),
                    ]
                  )
                : _c(
                    "div",
                    {
                      staticClass: "user-base-info",
                      attrs: { slot: "reference" },
                      on: { click: _vm.handleReferenceElmClick },
                      slot: "reference",
                    },
                    [
                      _vm.enableRefImage
                        ? _c(
                            "span",
                            {
                              staticClass: "avatar",
                              class:
                                "avatar-" + _vm.shape + " avatar-" + _vm.size,
                            },
                            [
                              _vm.src
                                ? _c("img", {
                                    staticClass: "avatar-img",
                                    attrs: {
                                      src: `${_vm.src}?size=${_vm.imgSize}`,
                                      alt: _vm.alt,
                                    },
                                  })
                                : _c("img", {
                                    staticClass: "avatar-img",
                                    attrs: {
                                      src: require("@src/assets/images/default-user.png"),
                                      width: _vm.imgSize,
                                      height: _vm.imgSize,
                                      alt: _vm.alt,
                                    },
                                  }),
                            ]
                          )
                        : _vm._e(),
                      _vm.enableName
                        ? _c("span", { staticClass: "user-name" }, [
                            _vm._v(_vm._s(_vm.realName)),
                            _vm.nickName
                              ? _c("span", { staticClass: "text-grey" }, [
                                  _vm._v("@" + _vm._s(_vm.nickName)),
                                ])
                              : _vm._e(),
                          ])
                        : _vm._e(),
                    ]
                  ),
            ]
          )
        : [
            _vm.enableLink
              ? _c(
                  "a",
                  {
                    staticClass: "avatar",
                    class: _vm.enableLink
                      ? "avatar-link avatar-" +
                        _vm.shape +
                        " avatar-" +
                        _vm.size
                      : "avatar-" + _vm.shape + " avatar-" + _vm.size,
                    attrs: { href: _vm.userLink, target: "_blank" },
                  },
                  [
                    _vm.src
                      ? _c("img", {
                          staticClass: "avatar-img",
                          attrs: {
                            src: `${_vm.src}?size=${_vm.imgSize}`,
                            alt: _vm.alt,
                          },
                        })
                      : _c("img", {
                          staticClass: "avatar-img",
                          attrs: {
                            src: require("@src/assets/images/default-user.png"),
                            width: _vm.imgSize,
                            height: _vm.imgSize,
                            alt: _vm.alt,
                          },
                        }),
                  ]
                )
              : _c(
                  "span",
                  {
                    staticClass: "avatar",
                    class: "avatar-" + _vm.shape + " avatar-" + _vm.size,
                  },
                  [
                    _vm.src
                      ? _c("img", {
                          staticClass: "avatar-img",
                          attrs: {
                            src: `${_vm.src}?size=${_vm.imgSize}`,
                            alt: _vm.alt,
                          },
                        })
                      : _c("img", {
                          staticClass: "avatar-img",
                          attrs: {
                            src: require("@src/assets/images/default-user.png"),
                            width: _vm.imgSize,
                            height: _vm.imgSize,
                            alt: _vm.alt,
                          },
                        }),
                  ]
                ),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }